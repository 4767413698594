import request from '@/utils/request'

export function verifyCert(data) {
	return request({
		url: '/verifyCert',
		method: 'post',
		data
	})
}

export function getInfo(data) {
	return request({
		url: '/getInfo',
		method: 'post',
		data
	})
}

export function sendCode(data) {
	return request({
		url: '/sendCode',
		method: 'post',
		data
	})
}
