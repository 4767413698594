import axios from 'axios'
import {
	Toast,
	showLoadingToast,
	closeToast,
	showDialog,
	showToast
} from 'vant';
import 'vant/lib/index.css';

// 创建axios实例
const service = axios.create({
	baseURL: process.env.VUE_APP_BASE_API, // api的base_url
	timeout: 15000, // 请求超时时间
	withCredentials: true
})

// request拦截器
service.interceptors.request.use(config => {
	var access_token = localStorage.getItem("access_token") ? localStorage.getItem("access_token") : '';
	config.headers['Authorization'] = access_token;

	return config
}, error => {
	// Do something with request error
	console.log(error) // for debug
	Promise.reject(error)
})

// respone拦截器
service.interceptors.response.use(
	response => {
		/**
		 * code为非200是抛错 可结合自己业务进行修改
		 */
		const res = response.data
		if (res.code !== 200) {
			closeToast();
			showDialog({
				title: '提示',
				message: res.message
			});

		}
		return response.data
	},
	error => {
		closeToast();
		console.log('err' + error) // for debug
		showDialog({
			title: '提示',
			message: error.message
		});
		return Promise.reject(error)
	}
)

export default service